import { BEPAVA } from "./abi";

export const networks = {

 

   bepbusd: {
    contract: "0x61046085a8c72f3ade603f9eabae644811a0b639", 
    TokenContract:"0x55d398326f99059fF775485246999027B3197955",
    chainId: "56",
    color: "0B32CC",
    projectId:"44",
    bonusamount:0,
    displaydecimals:8,
    fullName: "Binance-Peg BUSD Token",
    ABI: BEPAVA,
    mindeposit:100,
    maxdeposit:50000,
    mintransactionamount:10,
    maxtransactionamount:500,
    title: "bepbusd",
    logoname: "busd.png",
    rpcURl: "https://bscrpc.com",
    explorerlink: [{address:"0x61046085a8c72f3ade603f9eabae644811a0b639",link:"https://bscscan.com/address/0x61046085a8c72f3ade603f9eabae644811a0b639"}],
    blockchain: "Binance Smart Chain",
    type: "2",
    tokenId: "binance-usd",
    unit: "BUSD",
    TokenDecimals:18,
    explorer:"https://bscscan.com/tx/",
    networkData: [
      {
        chainId: "0x38",
        chainName: "BSC MainNet",
        rpcUrls: ["https://bscrpc.com"],
        nativeCurrency: {
          name: "BINANCE COIN",
          symbol: "BNB",
          decimals: 18,
        },
        blockExplorerUrls: ["https://bscscan.com/"],
      },
    ],
  }
};

export const networkschange = {
  eth: "eth",
  bsc: "bsc",
  polygon: "polygon",
  trx: "trx",
  usdt: "usdt",
  bepava:"bepava",
};
