import { useContext } from "react";
import BlockchainContext from "../../store/BlockchainContext";
import { walletAddressSlicer, numberWithCommas } from "../../utils/util";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
const HomePageMiddle = () => {
  const {
    handleConnectToWallet,
    activeAccount, handleInvest, btnText, currentcontractbalance,totalCollection,
    handleDisconnectWallet, checkEnable, tokenprice, totalRefEarn,refaddress, setrefaddress,
    handleWithdraw, lastdepositamount, RegisterUser, activeAccountBalancemaster, referraladdress,
    userTotalDeposits, userInvitedInfo, userwithdrawstatus, directBusiness, userTransactions,
    activeNetwork, harvestValue, UserTotalWithdrawn, userdownlinecount, depositdownline, referrallevel, directbusinessamount, directreferralrequired,
    activeAccountBalance } =
    useContext(BlockchainContext);
  const [amount, setAmount] = useState();
  const [mode, setmode] = useState(false);
  const [sellamount, setsellamount] = useState();
  const handleRegister = async (event) => {
    event.preventDefault();
    if (refaddress.length == 42)
      RegisterUser(refaddress);
    else
      toast.warning("Invalid Referral Account");
  }
  const setMax = () => {
    //console.log("max")
    setsellamount( numberWithCommas( ((userTotalDeposits * 5 - UserTotalWithdrawn)/ tokenprice ) > activeAccountBalancemaster ? activeAccountBalancemaster : ((userTotalDeposits * 5 - UserTotalWithdrawn)/ tokenprice),6));
  }
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if (btnText === "Register") {
      //handleInvest(amount);
      RegisterUser();
      //approveYourself(activeNetwork, activeAccount)
      setAmount("");
    }
    else {
      if (amount > 0) {
        handleInvest(amount);
        setAmount("");
      }
      else {
        console.log(" amount is less ", amount);
      }
    }
  };
  const selltokens = async (event) => {
    event.preventDefault();

    if (sellamount > 0) {
      handleWithdraw(sellamount);
      setsellamount("");
    }
    else {
      console.log(" amount is less ", sellamount);
    }
  };
  return (
    <>
      <section style={{ backgroundColor: "#373739" }}>
        <div class="pb-10">
          <div class="w-full flex justify-between md:px-14 px-2 py-4"><img src="assets/images/logo-lira.png" alt="logo" />
            {activeAccount ? (
              <button class="bg-black text-white border-2 border-yellow-400 font-medium px-2 rounded-md" onClick={() => handleDisconnectWallet()}>Disconnect</button>
            ) : (
              <button class="bg-black text-white border-2 border-yellow-400 font-medium px-2 rounded-md" onClick={() => handleConnectToWallet("wallet")}>Connect</button>
            )}
          </div>
          <div class="w-full md:px-14 px-2">
            <div class="md:border-8 border-4 border-yellow-600 flex flex-col items-center justify-center gold-bg py-4 md:px-32 px-2 mt-3">
              <h4 class="md:text-3xl text-xl font-semibold flex items-center md:gap-5 gap-2"><span class="text-[#088c0b]">LIVE PRICE -</span><span class="text-[#191970]">LTT/USDT</span></h4>
              <p class="md:text-5xl text-2xl text-[#373739] font-semibold md:mt-8 mt-4">{tokenprice}</p>
              <div class="w-full flex justify-between bg-[#373739] md:px-6 px-2 md:py-2 py-1 md:mt-8 mt-4 md:mb-5 font-semibold md:text-2xl gold-border" style={{fontSize: "19px"}}>
                <p class="text-white">LIRA Portfolio (LTT)</p>
                <p class="text-[#f7ef8a]">{numberWithCommas(activeAccountBalancemaster * tokenprice, activeNetwork.displaydecimals)}</p>
              </div>
            </div>
            <div class="md:mt-16 mt-5">
              
                  <div class="flex flex-col">
                    <div class="gold-bg flex font-semibold md:text-2xl p-1 rounded-md">
                      <label htmlFor="pool-contract" class="text-[#373739] w-1/2 flex justify-center items-center">Pool Contract</label><input type="text" value={walletAddressSlicer(activeNetwork.explorerlink[0].address)} name="poolContract" class="w-1/2 rounded-md focus:outline-none px-2 py-1" style={{ color: "#131313",textAlign: "end" }} />
                    </div>
                    <div class="gold-bg flex font-semibold md:text-2xl p-1 rounded-md md:mt-8 mt-3">
                      <label htmlFor="address" class="text-[#373739] w-1/2 flex justify-center items-center">Your Address</label><input type="text" name="address" value={activeAccount ? walletAddressSlicer(activeAccount):""} class="w-1/2 rounded-md focus:outline-none px-2 py-1" style={{ color: "#131313",textAlign: "end" }} />
                    </div>
                    <div class="gold-bg flex font-semibold md:text-2xl p-1 rounded-md md:mt-8 mt-3">
                      <label htmlFor="balance" class="text-[#373739] w-1/2 flex justify-center items-center">User USDT Balance</label><input type="number" value={numberWithCommas(activeAccountBalance, activeNetwork.displaydecimals)} name="balance" class="w-1/2 rounded-md focus:outline-none px-2 py-1" style={{ color: "#131313",textAlign: "end" }} />
                    </div>
                    {(userwithdrawstatus == false) ?
                (
                  <>
                    <div class="flex flex-col md:mt-10 mt-5 font-semibold">
                      <label htmlFor="sponsor-address" class="text-center md:text-3xl text-xl">Your Sponsor Address </label>
                      <input type="text" value={refaddress} onChange={(event) => { setrefaddress(event.target.value); }} name="sponsor-Address" class="w-full gold-border-2 md:mt-5 mt-2 md:text-2xl focus:outline-none px-2 py-1" style={{ color: "#131313" }} placeholder={`Enter Referrer address`} />
                    </div>
                    
                    <button class="self-center md:mt-10 mt-5 font-semibold md:text-2xl gold-bg text-[#373739] px-4 py-2 rounded-full" onClick={handleRegister}>Register</button>
                    </>
                    ) : (<></>)}
                  </div>
               
            </div>
            {(userwithdrawstatus == true) ?
                (
                  <>
            <div class="md:mt-16 mt-5 overflow-hidden rounded-md">
              <div class="gold-border-2 pb-5">
                <div class="flex justify-between font-semibold md:text-3xl">
                  <button class="bg-[#088d0b] hover:bg-green-700 md:w-[50%] w-1/2 py-2" onClick={() => setmode(false)}>BUY</button>
                  <button class="bg-[#dd1006] hover:bg-red-700 md:w-[50%] w-1/2 py-2" onClick={() => setmode(true)}>SELL</button>
                </div>
                {(mode == false) ?
                (
                <div class="md:px-4 px-2">
                  <div class="overflow-hidden rounded-md md:mt-10 mt-5">
                    <div class="flex gold-border-2 md:text-2xl font-semibold rounded-md">
                      <input type="text" name="usdtAmount" class="w-2/5 outline-none px-1 py-1" style={{ color: "#131313" }}
                      value={amount}  onChange={(event) => { setAmount(event.target.value); console.log(event.target.value); checkEnable(activeAccount); }} min={0.05} step={0.01} /><label htmlFor="usdtAmount" class="w-3/5 flex justify-center items-center gold-bg text-[#373739] rounded-s-md" >USDT Amount</label>
                    </div>
                    <br/>
                    <small className="min-text" style={{ "fontSize": "1.1em", display: "block", textAlign: "left",lineHeight: "25px" }}>Minimum Buy Amount is {numberWithCommas(activeNetwork.mindeposit, activeNetwork.displaydecimals)} and max {numberWithCommas(activeNetwork.maxdeposit, activeNetwork.displaydecimals)}</small>
                  </div>
                  <div class="overflow-hidden rounded-md md:mt-10 mt-4">
                    <div class="flex gold-border-2 md:text-2xl font-semibold rounded-md">
                      <input type="text" name="lttRecieved" value={(amount != null) ?numberWithCommas((amount / tokenprice) * 0.9, 6):""} class="w-2/5 outline-none px-1 py-1" style={{ color: "#131313" }} /><label htmlFor="lttRecieved" class="w-3/5 flex justify-center items-center gold-bg text-[#373739] rounded-s-md">LTT RECIEVED</label>
                    </div>
                  </div>
                  <div style={{width:"50%",display: "block",marginLeft: "auto",marginRight: "auto",paddingTop: "25px"}}>
                          <button className="i-btn1 i--btn-large i-w-1-1 i-text-uppercase" onClick={handleSubmitForm}>
                            <span >{btnText}</span>
                          </button>
                    </div>
                </div>
                ):(<></>)}
                {(mode == true) ?
                (
                <div class="md:px-4 px-2">
                  <div class="overflow-hidden rounded-md md:mt-10 mt-5">
                    <div class="flex gold-border-2 md:text-2xl font-semibold rounded-md">
                      <label htmlFor="usdtAmount" class="w-3/5 flex justify-center items-center gold-bg text-[#373739] rounded-s-md" >LTT Amount</label>
                      <input type="text" name="usdtAmount" class="w-2/5 outline-none px-1 py-1" style={{ color: "#131313" }} value={sellamount}  onChange={(event) => { setsellamount(event.target.value); console.log(event.target.value); }} min={0.05} step={0.01} />
                    </div>
                    <div style={{
                                marginTop: "-28px", marginBottom: "32px", textAlign: "end", marginRight: "13px", cursor: "pointer",color:"#c4a542"
                              }} onClick={setMax}>Max</div>
                    <small className="min-text" style={{ "fontSize": "1.1em", display: "block", textAlign: "left",lineHeight: "25px" }}>Minimum sell Amount is {numberWithCommas(1.1/tokenprice, activeNetwork.displaydecimals)} LIRA</small>          
                  </div>
                  <div class="overflow-hidden rounded-md md:mt-10 mt-4">
                    <div class="flex gold-border-2 md:text-2xl font-semibold rounded-md">
                      <label htmlFor="lttRecieved" class="w-3/5 flex justify-center items-center gold-bg text-[#373739] rounded-s-md">USDT RECIEVED</label>
                      <input type="text" name="lttRecieved" class="w-2/5 outline-none px-1 py-1" style={{ color: "#131313" }} value={(sellamount !=null)? numberWithCommas(sellamount * tokenprice * 0.93, 6):""} />
                    </div>
                    <div style={{width:"50%",display: "block",marginLeft: "auto",marginRight: "auto",paddingTop: "25px"}}>
                          <button className="i-btn1 i--btn-large i-w-1-1 i-text-uppercase" onClick={selltokens}>
                            <span >Sell</span>
                          </button>
                    </div>
                  </div>
                </div>
                 ):(<></>)}
              </div>
            </div>
            </>
                    ) : (<></>)}
            <div class="md:mt-20 mt-5">
              <h2 class="text-center md:text-3xl text-xl font-semibold">PERSONAL STATISTIC</h2>
              <div class="mt-3 overflow-hidden rounded-md">
                <div class="gold-border-2 py-5 md:px-7 px-2 flex flex-col gap-3">
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">User LIRA Balance</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(activeAccountBalancemaster, 5)}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Lira USDT Portfolio</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(activeAccountBalancemaster * tokenprice, activeNetwork.displaydecimals)}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Total Deposited (USDT)</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(userTotalDeposits, activeNetwork.displaydecimals)}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Total Withdrawl (USDT)</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(UserTotalWithdrawn, activeNetwork.displaydecimals)}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Available Limit (USDT)</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(userTotalDeposits * 5 - UserTotalWithdrawn, activeNetwork.displaydecimals)}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Total Affiliate Bonus</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(userInvitedInfo[1], activeNetwork.displaydecimals)}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Invited User</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{userInvitedInfo[0]}</p>
                    </div>
                  </div>
                  <div class="overflow-hidden rounded-md">
                    <div class="gold-border-2 flex md:text-xl font-semibold">
                      <p class="w-3/5 flex items-center md:pl-4 pl-1">Direct Contribution</p>
                      <p class="w-2/5 gold-bg flex justify-end items-center  pr-4 text-[#19196e] py-1 rounded-s-md">{numberWithCommas(totalCollection, activeNetwork.displaydecimals)}</p>
                    </div>
                  </div>
                  <div class="flex justify-between">
                    <div class="overflow-hidden rounded-md md:w-1/2 w-3/4">
                      <div class="gold-border-2 md:text-xl font-semibold flex gap-2 py-1">
                        <p class="md:pl-2 pl-1">Affiliate Link :-</p>
                        <p class="text-red-500"></p>
                      </div>
                    </div>
                    {((activeAccount) && (userwithdrawstatus == true) && (userTotalDeposits > 0)) ? (
                      <CopyToClipboard
                        text={`https://${window.location.host}?ref=${activeAccount}`}
                        onCopy={() => {
                          toast.success("personal link Copied Successfully");
                        }}
                      ><button class="gold-bg text-xl font-semibold text-[#373739] px-3 rounded-2xl">COPY</button>
                      </CopyToClipboard>
                    ) : (
                      <CopyToClipboard
                        text={`https://${window.location.host}?ref=${activeNetwork.contract}`}
                        onCopy={() => {
                          toast.success("Referral link Copied Successfully");
                        }}
                      >
                        <button class="gold-bg text-xl font-semibold text-[#373739] px-3 rounded-2xl">COPY</button>
                      </CopyToClipboard>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="md:mt-20 mt-5">
              <h2 class="text-center md:text-3xl text-xl font-semibold">AFFILIATE PROGRAM</h2>
              <div class="overflow-hidden rounded-md mt-3">
                <div class="gold-border-2">
                  <table class="w-full md:text-2xl font-semibold">
                    <thead>
                      <tr>
                        <th class="gold-bg border-r-2 text-[#373739] text-center py-4">Level</th>
                        <th class="gold-bg border-r-2 text-[#373739] text-center py-4">Affiliate %</th>
                        <th class="gold-bg border-r-2 text-[#373739] text-center py-4">Direct</th>
                        <th class="gold-bg text-[#373739] py-4 text-center ">Bonus</th>
                      </tr>
                    </thead>
                    <tbody>
                      {referrallevel
                        ? referrallevel.map((deposit, index) => (
                          <tr key={index}>
                            <td class="border-r border-b border-yellow-500 text-center py-1">{index + 1}</td>
                            <td class="border-r border-b border-yellow-500 text-center py-1">{deposit} %</td>
                            <td class="border-r border-b border-yellow-500 text-center py-1">{directreferralrequired[index + 1]}</td>
                            <td class="border-r border-b border-yellow-500 py-1">{numberWithCommas(depositdownline[index], activeNetwork.displaydecimals)}</td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-12 col-md-12 col-sm-12">
              <div className="b-block__title ">
                <div>OPPORTUNITY</div>
              </div>
              <div className="b-block__body">
                <section className="section_banner section_gradiant small_pb">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 oppurtunitydiv">
                        <div className="title_default_light title_border text-center">
                          <h4>LIRA Business Module</h4>
                          <p>On self Contribution System will allow taking till 500% in all ways, like LTT price increase & referral bonus.</p>
                          <p>Participate can buy sell any time and enhance limit also to get more price increase.</p>
                        </div>
                      </div>
                      <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 oppurtunitydiv">
                        <div className="title_default_light title_border text-center">
                          <h4>Buy Structure</h4>
                          <p>Basic buying or contribution for community, buying  is min. 100 USDT. Max. 50000 USDT.</p>
                    
                        </div>
                      </div>
                      <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 oppurtunitydiv">
                        <div className="title_default_light title_border text-center">
                          <h4>Level Referral Bonus</h4>
                          <p>User will get up to 5 levels referral bonus for each level user need direct condition like for 1st level need 1 direct same for 5 levels need 5 direct.</p>
                    
                        </div>
                      </div>
                      <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 oppurtunitydiv">
                        <div className="title_default_light title_border text-center">
                          <h4>Referral Bonus</h4>
                          <p>user will get up to 5 level token allotment  which are like for 1st level 1% and from 2nd to 5th levels 0.5 % each level.</p>
                    
                        </div>
                      </div>
                    </div>
                  </div>
                </section></div>
            </div>
            <div className="col-lg-12 offset-lg-12 col-md-12 col-sm-12">
              <div className="b-block__title">
                <div style={{paddingBottom: "12px"}}>Important Criteria Rules:-</div></div>
              <div className="b-block__body">
                <section className="section_banner section_gradiant small_pb">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                        <div className="title_default_light title_border text-center">
                          <p>For stable system geometric architecture some criteria rules are working as follows:</p> <br />
                          <h4>On self token Contribution System will allow taking till 500% in all ways, like LTT price increase &amp; referral bonus.</h4>
                          <h6>Participate can buy or sell token any time. And after complete 500% to increase and enhance limit user need to buy tokens for getting available limit</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </section></div>
            </div>
            <div className="col-lg-12 offset-lg-12 col-md-12 col-sm-12">
              <div className="b-block__title" style={{marginBottom:"0px"}}>
                    <div>Faq:-</div>
                  </div>
                  <div className="b-block__body">
                    <section className="small_pb small_pt">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-8 col-md-12 offset-lg-2">
                            <div className="title_default_light title_border text-center">
                              <h4 className="animation" data-animation="fadeInUp" data-animation-delay="0.2s" style={{marginBottom: "10px"}}>ASK ME</h4>
                              <p className="animation" data-animation="fadeInUp" data-animation-delay="0.4s">Frequently asked questions (FAQ) or Questions and Answers (Q&amp;A), are listed questions and answers, all supposed to be commonly asked in some context</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="tab_content">
                              <div className="tab-content half_tab">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div id="accordion1" className="faq_content2">
                                        <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                          <div className="card-header" id="headingOne">
                                            <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is LIRA?</a> </h6>
                                          </div>
                                          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion1">
                                            <div className="card-body"> LIRA is a multi-level concept of earning money by investing a small amount and also making a community of like-minded people ready to contribute. It runs on Binance Smart Contract system where the website is just an interface for the convenience of working with a smart contract.</div>
                                          </div>
                                        </div>
                                        <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.6s">
                                          <div className="card-header" id="headingTwo">
                                            <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Who administers LIRA?</a> </h6>
                                          </div>
                                          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion1">
                                            <div className="card-body"> There is no administrator. There is a creator who uploaded the contract code to the Binance smart chain. Since then, the smart contract has been part of the overall network, which is supported by miners. No one has the right to affect the operation of a smart contract, delete it or stop it. Any attempt to make unauthorized changes will be rejected due to inconsistency with previous copies in the block chain.</div>
                                          </div>
                                        </div>
                                        <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.8s">
                                          <div className="card-header" id="headingThree">
                                            <h6 className="mb-0"> <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How does it pay?</a> </h6>
                                          </div>
                                          <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion1">
                                            <div className="card-body"> It follows a wallet to wallet system of payment as per token price . So there is no chance any amount goes in the wrong hands. as per  security and code no one can change any code.</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12" style={{ margin: "12px 0 0 0" }}>
                                      <div id="accordion2" className="faq_content2">
                                        <div className="card animation" data-animation="fadeInUp" data-animation-delay="0.4s">
                                          <div className="card-header" id="headingFive">
                                            <h6 className="mb-0"> <a data-toggle="collapse" href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">What currency does LIRA work with?</a> </h6>
                                          </div>
                                          <div id="collapseFive" className="collapse show" aria-labelledby="headingFive" data-parent="#accordion2">
                                            <div className="card-body"> LIRA works with USDT in Binance smart chain (BEP-20), it is one of the well-known crypto currencies in the world currently.</div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> </div>
            </div>
          </div>
        </div>



      </section>
      <section className="" >
        <div className="" >
          <main id="App" className="layout" >
            <div className="i-container">
              <div className="i-margin-large-bottom" style={{ display: "none" }}>
                <div className="b-logo i-text-center">
                  <img src="assets/images/LIRA.png" /></div>
              </div>
              <div className="b-index-content" style={{ display: "none" }}>
                <div className="b-index-content__1 i-margin-large-bottom">
                  <p className="i-text-uppercase i-text-center i-margin-bottom" style={{ fontSize: "20px" }}>Price {tokenprice} LTT/USDT</p>

                  {activeAccount ? (
                    <div className="">
                      <p className="m-0 address-text" style={{ fontSize: "16px", lineHeight: "20px" }}>
                      </p>
                      <button
                        onClick={() => handleDisconnectWallet()}
                        className={`i-btn i--btn-large i-w-1-1`}
                        style={{ padding: "8px 30px !important" }}
                      >
                        <span>Disconnect</span>
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleConnectToWallet("wallet")}
                      className={`i-btn i--btn-large i-w-1-1`}
                      style={{ padding: "8px 30px !important" }}
                    >
                      <span>Connect</span>
                    </button>
                  )}
                </div>
                <div className="b-index-content__4 i-margin-large-bottom">
                  <table className="table  table-borderless">
                    <tbody>
                      <tr>
                        <td>Smart contract:-</td>
                        <td><a href={activeNetwork.explorerlink[0].link} target="_blank">{walletAddressSlicer(activeNetwork.explorerlink[0].address)}</a></td>
                      </tr>

                      <tr>
                        <td>Your address:-</td>
                        <td>{activeAccount ? (walletAddressSlicer(activeAccount)) : ("...")}</td>
                      </tr>
                      <tr>
                        <td>User USDT balance:-</td>
                        <td>{numberWithCommas(activeAccountBalance, activeNetwork.displaydecimals)}{" "} </td>
                      </tr>
                      <tr>
                        <td>User reffered by:-</td>
                        <td>{referraladdress ? (walletAddressSlicer(referraladdress)) : ("...")}{" "} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {(userwithdrawstatus == true) ?
                  (
                    <div>
                      <div className="b-index-content__3 i-margin-large-bottom">
                        <div className="i-margin-bottom">
                          <div className="b-index-content__calc-title">
                            <strong>Participate </strong><span>{numberWithCommas(activeAccountBalance, activeNetwork.displaydecimals)} BUSD</span>
                            <i className="b-index-content__calc-reset">
                            </i>
                          </div>
                        </div>
                        <div className="b-index-content__buttons i-grid">
                          <div className="plan-box  justify-content-left mt-3">
                            <div className="stake-input-holder"><input type="number" value={amount} className="stake-input" placeholder={`Enter ${activeNetwork.unit} amount`}
                              onChange={(event) => { setAmount(event.target.value); console.log(event.target.value); checkEnable(activeAccount); }}
                              min={0.05}
                              step={0.01} /></div>
                            <small className="min-text" style={{ "fontSize": "1.1em", display: "block", textAlign: "center" }}>Minimum Buy Amount is {numberWithCommas(activeNetwork.mindeposit, activeNetwork.displaydecimals)} and max {numberWithCommas(activeNetwork.maxdeposit, activeNetwork.displaydecimals)} {activeNetwork.unit}</small>
                            {(amount != null) ?
                              (
                                <>
                                  <br /><br /><small className="min-text" style={{ "fontSize": "1.5em", display: "block", textAlign: "center" }}>You get {numberWithCommas((amount / tokenprice) * 0.97, 6)} LTT</small>
                                </>
                              ) : (<></>)}
                          </div>

                        </div>
                        <div>
                          <button className="i-btn i--btn-large i-w-1-1 i-text-uppercase" onClick={handleSubmitForm}>
                            <span >{btnText}</span>
                          </button></div></div>

                      <div className="b-index-content__3 i-margin-large-bottom">
                        <div className="i-margin-bottom">
                          <div className="b-index-content__calc-title">
                            <strong>Sell </strong><span>{numberWithCommas(activeAccountBalancemaster, activeNetwork.displaydecimals)} Lira Trade Token (LTT)</span>
                            <i className="b-index-content__calc-reset">
                            </i>
                          </div>
                        </div>
                        <div className="b-index-content__buttons i-grid">
                          <div className="plan-box  justify-content-left mt-3">
                            <div className="stake-input-holder"><input type="number" value={sellamount} className="stake-input"
                              onChange={(event) => { setsellamount(event.target.value); console.log(event.target.value); }}
                              min={0.05}
                              step={0.01} />
                              <div style={{
                                marginTop: "-37px", marginBottom: "32px", textAlign: "end", marginRight: "13px", cursor: "pointer"
                              }} onClick={setMax}>Max</div>
                            </div>
                            {(sellamount != null) ?
                              (
                                <>
                                  <br /><br /><small className="min-text" style={{ "fontSize": "1.5em", display: "block", textAlign: "center" }}>You get {numberWithCommas(sellamount * tokenprice * 0.93, 6)} USDT</small>
                                </>
                              ) : (<></>)}
                          </div>

                        </div>
                        <div>
                          <button className="i-btn i--btn-large i-w-1-1 i-text-uppercase" onClick={selltokens}>
                            <span >Sell</span>
                          </button></div></div>
                    </div>

                  ) : (
                    <div className="b-index-content__3 i-margin-large-bottom">
                      <div className="i-margin-bottom">
                        <div className="b-index-content__calc-title">
                          <span>Your Sponsor Id</span>
                          <i className="b-index-content__calc-reset">
                          </i>
                        </div>
                      </div>
                      <div className="b-index-content__buttons i-grid">
                        <div className="plan-box  justify-content-left mt-3">
                          <div className="stake-input-holder"><input type="text" value={refaddress} className="stake-input" placeholder={`Enter Referrer address`}
                            onChange={(event) => { setrefaddress(event.target.value); console.log(event.target.value); }} /></div>
                        </div>

                      </div>
                      <div>
                        <button className="i-btn i--btn-large i-w-1-1 i-text-uppercase" onClick={handleRegister}>
                          <span >Register</span>
                        </button></div></div>
                  )}
              </div>
              <div >
                <div className="b-block i-margin-large-bottom" style={{ display: "none" }}>
                  <div className="b-block__title"><div> Personal Statistic</div></div>
                  <div className="b-block__body right">
                    <table className="table  table-borderless total_border">
                      <tbody>
                        <tr>
                          <td>User LIRA balance:-</td>
                          <td>{numberWithCommas(activeAccountBalancemaster, activeNetwork.displaydecimals)}{" "} </td>
                        </tr>
                        <tr>
                          <td>LIRA USDT VALUE:-</td>
                          <td>{numberWithCommas(activeAccountBalancemaster * tokenprice, activeNetwork.displaydecimals)}{" "} </td>
                        </tr>
                        <tr>
                          <td>Total Deposited:-</td>
                          <td>{numberWithCommas(userTotalDeposits, activeNetwork.displaydecimals)}{" "} </td>
                        </tr>
                        <tr>
                          <td>Total Withdrawal :-  </td>
                          <td>{numberWithCommas(UserTotalWithdrawn, activeNetwork.displaydecimals)}{" "} </td>
                        </tr>
                        <tr>
                          <td>Available Limit :- </td>
                          <td>{numberWithCommas(userTotalDeposits * 5 - UserTotalWithdrawn, activeNetwork.displaydecimals)}{" "} </td>
                        </tr>
                        <tr>
                          <td>Total Referral Bonus :-  </td>
                          <td>{numberWithCommas(userInvitedInfo[1], activeNetwork.displaydecimals)}{" "} </td>
                        </tr>

                        <tr>
                          <td>Invited Users :-</td>
                          <td>{userInvitedInfo[0]}</td>
                        </tr>
                        <tr>
                          <td>Referral Link</td>
                          <td>
                            {((activeAccount) && (userwithdrawstatus == true) && (userTotalDeposits > 0)) ? (
                              <CopyToClipboard
                                text={`https://${window.location.host}?ref=${activeAccount}`}
                                onCopy={() => {
                                  toast.success("personal link Copied Successfully");
                                }}
                              ><button className="pers-copy-btn">Copy Link</button>
                              </CopyToClipboard>
                            ) : (
                              <CopyToClipboard
                                text={`https://${window.location.host}?ref=${activeNetwork.contract}`}
                                onCopy={() => {
                                  toast.success("Referral link Copied Successfully");
                                }}
                              >
                                <button className="pers-copy-btn">Copy Link</button>
                              </CopyToClipboard>

                            )}
                          </td>
                        </tr>
                        <tr style={{ display: "none" }}>
                          <td>Direct Business :– </td>
                          <td>{numberWithCommas(totalRefEarn, activeNetwork.displaydecimals)}{" "} {activeNetwork.unit}</td>
                        </tr>

                      </tbody>
                    </table><br />

                  </div></div>

                <div className="b-block i-margin-large-bottom" style={{ display: "none" }}>
                  <div className="b-block__title">
                    <div>User Transactions</div></div>
                  <div className="b-block__body">

                    <table className="table table-borderless text-center total_border">

                      <tbody>

                        <tr>
                          <td> Wallet</td>
                          <td> Amount</td>
                          <td> ROI</td>
                          <td> Deposit Mode</td>
                        </tr>
                        {userTransactions
                          ? userTransactions.map((deposit, index) => (
                            <tr key={index}><td><span>{walletAddressSlicer(deposit.account)}</span></td>
                              <td><span>{deposit.amount} {deposit.chain}</span> </td>
                              <td><span>{deposit.roi / 20}</span> </td>
                              <td><span>{deposit.deposittype}</span> </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>

                  </div></div>
                <div className="b-block i-margin-large-bottom" style={{ display: "none" }}>
                  <div className="b-block__title">
                    <div>Affiliate Program</div></div>
                  <div className="b-block__body">

                    <table className="table table-borderless text-center total_border">

                      <tbody>
                        <tr>
                          <td> Level</td>
                          <td> Total Bonus</td>
                          <td> Direct Count</td>
                          <td> Income Received</td>
                        </tr>
                        {referrallevel
                          ? referrallevel.map((deposit, index) => (
                            <tr key={index}>
                              <td> Level {index + 1} </td>
                              <td> {deposit} % </td>
                              <td> {directreferralrequired[index + 1]} </td>
                              <td> {depositdownline[index]} </td>

                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>

                  </div></div>

                {/* opportunity section start */}
                <div id="opportunity" className="b-block i-margin-large-bottom">
                </div>
                {/* opportunity section end */}
                {/* criteria section start */}
                <div id="criteria" className="b-block i-margin-large-bottom">
                </div>
                {/* criteria section end */}
                {/* faq section start */}
                <div id="faq" className="b-block i-margin-large-bottom" style={{ "display": "none" }}>
                  </div>
                {/* faq section end */}
              </div>
            </div>
            <div className="b-footer">
              <div className="i-container">
                <ul className="i-list b-footer_links">
                  <li>
                    <a href={activeNetwork.explorerlink[0].link} target="_blank" >Smart Contract</a>
                  </li>
                </ul>
                <p className="copyright">Copyright © 2023 <span className="LIRA">LIRA</span>. All Rights Reserved.</p>
              </div></div>
          </main>
        </div>

      </section>

    </>
  )
}

export default HomePageMiddle
